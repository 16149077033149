import React from "react"
import SubLayout from "../components/Sublayout"
import Area from "../components/structural/Area"
import { LocaleStoreContext } from "../components/LocaleStore"

import styled from "styled-components"

const Container = styled.div`
  display: flex;

  iframe {
    margin: auto;
    height: 6800px;
    @media screen and (max-width: 620px) {
      height: 8000px;
    }
    @media screen and (max-width: 430px) {
      height: 9000px;
    }
  }
`
function Quiz() {
  return (
    <SubLayout>
      <Area>
        <Container>
          <LocaleStoreContext.Consumer>
            {(context) =>
              context.locale === "de" ? (
                <iframe
                  id="iframe"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSeZjo9eNn5Ef_8vpHnxLEmidSgO0omlSmLN8K5ySugTg7ajLw/viewform?embedded=true"
                  width="640"
                  height="50"
                  frameBorder="0"
                  marginHeight="0"
                  title="quiz"
                >
                  Loading…
                </iframe>
              ) : (
                <iframe
                  id="iframe"
                  src="https://docs.google.com/forms/d/e/1FAIpQLScvR2QyAsAHE5X0uFKxn-fEM5KNL5Mg3gBJZgPx_jsbg57A5w/viewform?embedded=true"
                  width="640"
                  height="50"
                  frameBorder="0"
                  marginHeight="0"
                  title="quiz"
                >
                  Loading…
                </iframe>
              )
            }
          </LocaleStoreContext.Consumer>
        </Container>
      </Area>
    </SubLayout>
  )
}

export default Quiz
