import React from "react"

const areaStyle = {
  paddingTop: "128px",
  paddingBottom: "128px",
  paddingLeft: "8px",
  paddingRight: "8px",
}

const areaStyleWhite = { ...areaStyle, backgroundColor: "#ffffff" }

export default function Area({ className, white, children }) {
  if (white) {
    return (
      <div style={areaStyleWhite} className={className}>
        {children}
      </div>
    )
  }
  return (
    <div style={areaStyle} className={className}>
      {children}
    </div>
  )
}
